import Model from "@/models/Model";

const params = () => ({
  height: { measures: {} },
  weight: { measures: {} },
  weight_start: { measures: {} },
  weight_ideal: { measures: {} },
  breast: { measures: {} },
  biceps: { measures: {} },
  stomach: { measures: {} },
  waist: { measures: {} },
  thign: { measures: {} },
  calories: { measures: {} },
  calories_burned: { measures: {} },
  water: { measures: {} },
  steps: { measures: {} },

  // NUTRIENTS
  carbohydrates: { measures: {} },
  fat: { measures: {} },
  protein: { measures: {} },
});

export default class Measure extends Model {
  constructor(data = {}) {
    super(data);
    this.load(data);

    this.unit_system = { id: 0, code: "", name: "" };

    this.loaded = false;
  }

  load(data = {}) {
    this.params = { ...params(), ...data };
  }

  unit(paramName) {
    const param = this.params[paramName];

    if (!param) return null;

    const measure = param.measures[this.unit_system.code] || {};

    return measure.unit || null;
  }

  set unitSystem(val) {
    this.unit_system = { ...val };
    this.loaded = true;
  }

  get unitSystem() {
    return this.unit_system;
  }

  get height() {
    return this.unit("height");
  }

  get weight() {
    return this.unit("weight");
  }

  get weight_start() {
    return this.unit("weight_start");
  }

  get weight_ideal() {
    return this.unit("weight_ideal");
  }

  get breast() {
    return this.unit("breast");
  }

  get biceps() {
    return this.unit("biceps");
  }

  get stomach() {
    return this.unit("stomach");
  }

  get waist() {
    return this.unit("waist");
  }

  get thign() {
    return this.unit("thign");
  }

  get calories() {
    return this.unit("calories");
  }

  get calories_burned() {
    return this.unit("calories_burned");
  }

  get water() {
    return this.unit("water");
  }

  get steps() {
    return this.unit("steps");
  }

  get carbohydrates() {
    return this.unit("carbohydrates");
  }

  get protein() {
    return this.unit("protein");
  }

  get fat() {
    return this.unit("fat");
  }
}
