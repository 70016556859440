<template>
  <svg class="svg-icon --fill" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15.8334 1.66699C15.6124 1.66699 15.4004 1.75479 15.2441 1.91107C15.0878 2.06735 15 2.27931 15 2.50033V7.05033L14.1667 7.60866V2.50033C14.1667 2.27931 14.0789 2.06735 13.9226 1.91107C13.7663 1.75479 13.5544 1.66699 13.3334 1.66699C13.1124 1.66699 12.9004 1.75479 12.7441 1.91107C12.5878 2.06735 12.5 2.27931 12.5 2.50033V7.60866L11.6667 7.05033V2.50033C11.6667 2.27931 11.5789 2.06735 11.4226 1.91107C11.2663 1.75479 11.0544 1.66699 10.8334 1.66699C10.6124 1.66699 10.4004 1.75479 10.2441 1.91107C10.0878 2.06735 10 2.27931 10 2.50033V7.50033C10.0008 7.63758 10.0354 7.77254 10.1008 7.8932C10.1662 8.01387 10.2604 8.1165 10.375 8.19199L12.5 9.61699V17.5003C12.5 17.7213 12.5878 17.9333 12.7441 18.0896C12.9004 18.2459 13.1124 18.3337 13.3334 18.3337C13.5544 18.3337 13.7663 18.2459 13.9226 18.0896C14.0789 17.9333 14.1667 17.7213 14.1667 17.5003V9.61699L16.2917 8.19199C16.4063 8.1165 16.5006 8.01387 16.566 7.8932C16.6314 7.77254 16.666 7.63758 16.6667 7.50033V2.50033C16.6667 2.27931 16.5789 2.06735 16.4226 1.91107C16.2663 1.75479 16.0544 1.66699 15.8334 1.66699ZM7.50004 1.66699C6.39497 1.66699 5.33516 2.10598 4.55376 2.88738C3.77236 3.66878 3.33337 4.72859 3.33337 5.83366V10.8337C3.33337 11.0547 3.42117 11.2666 3.57745 11.4229C3.73373 11.5792 3.94569 11.667 4.16671 11.667H6.66671V17.5003C6.66671 17.7213 6.7545 17.9333 6.91079 18.0896C7.06707 18.2459 7.27903 18.3337 7.50004 18.3337C7.72105 18.3337 7.93302 18.2459 8.0893 18.0896C8.24558 17.9333 8.33337 17.7213 8.33337 17.5003V2.50033C8.33337 2.27931 8.24558 2.06735 8.0893 1.91107C7.93302 1.75479 7.72105 1.66699 7.50004 1.66699ZM6.66671 10.0003H5.00004V5.83366C4.99977 5.31645 5.15992 4.81189 5.45842 4.38951C5.75692 3.96713 6.17908 3.64773 6.66671 3.47533V10.0003Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DietIcon",
};
</script>

<style scoped></style>
