<template>
  <div id="app">
    <component v-show="!globalOverlay" :is="CurrentLayout">
      <router-view />
    </component>

    <GlobalOverlay />
  </div>
</template>
<script>
import MainLayout from "@/layouts/MainLayout";
import QuizLayout from "@/layouts/QuizLayout";
import SignLayout from "@/layouts/SignLayout";
import LandingLayout from "@/layouts/LandingLayout";

import app from "@/mixins/app";

import { getAccessToken } from "@/auth";
import GlobalOverlay from "@/components/GlobalOverlay";
import { mapGetters } from "vuex";

export default {
  mixins: [app],
  components: {
    GlobalOverlay,
    MainLayout,
    QuizLayout,
    SignLayout,
    LandingLayout,
  },
  computed: {
    ...mapGetters("app", ["globalOverlay"]),
    CurrentLayout() {
      const route = this.$route;
      const { layout } = this.$route.meta;

      if (layout) {
        return layout;
      }

      if (route.path === "/") {
        return QuizLayout;
      }

      return MainLayout;
    },
  },
  data: () => ({}),
  created() {
    const accessToken = getAccessToken();

    if (accessToken) {
      this.fetchUser();
    }
  },
};
</script>

<style lang="scss">
@import "./assets/scss/mixins/mixins";
@import "./assets/scss/external";
</style>
