import { BContainer, BCol, BOverlay, BRow } from "bootstrap-vue";

import Button from "@/components/Button";

const url = window.location.hostname === "localhost" ? "http://stripe.loc" : window.location.origin;

export default {
  components: {
    Button,
    BContainer,
    BCol,
    BOverlay,
    BRow,
  },
  props: {},
  computed: {
    appUrl() {
      return url;
    },
  },
  methods: {
    setTitle(title) {
      document.title = this.$t(title);
    },
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    getDate(date) {
      return window.getDate(date);
    },
    dateFormat(date, separator) {
      return window.dateFormat(date, separator);
    },
    getProp(obj, key) {
      return window.getProp(obj, key);
    },
  },
  mounted() {},
};
