import HomeIcon from "@/components/icons/HomeIcon";
import ProfileIcon from "@/components/icons/ProfileIcon";
import DietIcon from "@/components/icons/DietIcon";
import ClipBoardIcon from "@/components/icons/ClipBoardIcon";
import FireIcon from "@/components/icons/FireIcon";
import MedalIcon from "@/components/icons/MedalIcon";

import { logout } from "@/auth";

export default [
  {
    icon: HomeIcon,
    title: "sidebar.dashboard",
    component: () => import("@/views/dashboard/DashboardView"),
    path: "/dashboard",

    meta: {
      lockable: false,
    },
  },
  {
    icon: DietIcon,
    title: "sidebar.diet",
    component: () => import("@/views/diet/DietView"),
    path: "/diets",
    meta: {},
  },
  {
    icon: ClipBoardIcon,
    title: "sidebar.grocery-list",
    component: () => import("@/views/grocery-list/GroceryListView"),
    path: "/grocery-list",
    meta: {},
  },
  {
    icon: FireIcon,
    title: "sidebar.trainings",
    component: () => import("@/views/training/TrainingsView"),
    path: "/trainings",
    meta: {},
  },
  {
    icon: MedalIcon,
    title: "sidebar.challenges",
    component: () => import("@/views/challenge/ChallengeView"),
    path: "/challenges",
    meta: {
      soon: true,
    },
  },
  {
    icon: MedalIcon,
    title: "Menu for Children",
    component: () => import("@/views/menu-for-children/MenuForChildrenView"),
    path: "/menu-for-children",
    meta: {
      soon: true,
    },
  },
  {
    icon: MedalIcon,
    title: "eBooks for health",
    component: () => import("@/views/ebooks/EbooksView"),
    path: "/ebooks",
    meta: {
      soon: true,
    },
  },
  {
    icon: MedalIcon,
    title: "Coins",
    component: () => import("@/views/coins/CoinsView"),
    path: "/coins",
    meta: {
      soon: true,
    },
  },
  {
    icon: MedalIcon,
    title: "Bring a friend",
    component: () => import("@/views/bring-a-friend/BringAFriendView"),
    path: "/bring-a-friend",
    meta: {
      soon: true,
    },
  },
];
