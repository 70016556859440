import Model from "@/models/Model";

const metric = () => ({
  value: 0,
  max: 100,
  unit: "",
});

export default class UserProgress extends Model {
  constructor(data = {}) {
    super(data);
    this.load(data);

    this.loaded = new Promise((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  load(data) {
    const { calories, calories_burned, steps, water } = data;

    this.calories = calories || metric();
    this.calories_burned = calories_burned || metric();
    this.steps = steps || metric();
    this.water = water || metric();

    if (calories) {
      this.resolvePromise(true);
    }
  }
}
