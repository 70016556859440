Number.prototype.padLeft = function (base, chr) {
  const len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

window.appUrl = window.location.hostname === "localhost" ? "http://stripe.loc" : window.location.origin;

window.getDate = function (date = null) {
  const d = date instanceof Date ? date : new Date(date);

  return (
    [(d.getMonth() + 1).padLeft(), d.getDate().padLeft(), d.getFullYear()].join("/") +
    " " +
    [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":")
  );
};

window.dateFormat = function (date, separator = "-") {
  const d = date instanceof Date ? date : new Date(date);

  return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join(separator);
};

window.getProp = function (obj, key) {
  return key.split(".").reduce((a, b) => a[b] || null, obj);
};

window.getTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds / 60) % 60);
  const s = seconds % 60;

  return [h, m, s];
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
window.mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (!source.hasOwnProperty(key)) continue;

      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

window.openLink = (router, routeName, locale = "en") => {
  const routeData = router.resolve({ name: routeName });
  window.open(routeData.href, "_blank");
};
