import Vue from "vue";
import { BVToastPlugin, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App.vue";

import i18n from "@/libs/i18n";

import "@/globals";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Form from "vform";
import api from "@/api";

Form.axios = api;
window.Form = Form;

Vue.config.productionTip = false;

import global from "@/mixins/global";

Vue.mixin(global);
Vue.use(BVToastPlugin);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import "@/assets/scss/core.scss";
import "@/assets/scss/components/bf-datepicker.scss";
import "./registerServiceWorker";
