import { isUserLoggedIn, register } from "@/auth";
import LandingLayout from "@/layouts/LandingLayout";
import QuizLayout from "@/layouts/QuizLayout";

const beforeEnter = (to, from, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (isLoggedIn) {
    return next({ name: "dashboard" });
  }

  return next();
};

export default [
  {
    path: "/landing",
    name: "landing",
    component: () => import("@/views/new-landing/LandingView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/sales-landing",
    name: "sales-landing",
    component: () => import("@/views/sales-landing/SalesLandingView.vue"),
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    props: true,
    component: () => import("@/views/checkout/CheckoutView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/did-you-know",
    name: "did-you-know",
    component: () => import("@/views/checkout/DidYouKnowView.vue"),
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
  },
  {
    path: "/old-landing",
    name: "old-landing",
    component: () => import("@/views/landing/LandingView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/finish-quiz",
    name: "finish-quiz",
    component: () => import("@/views/new-quiz/QuizInformationLoaderView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "new-quiz-result" });
      }

      return next();
    },
  },
  {
    path: "/login-after-quiz",
    name: "login-after-quiz",
    component: () => import("@/views/new-quiz/LoginAfterQuizView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "new-quiz-result" });
      }

      return next();
    },
  },
  {
    path: "/quiz",
    name: "quiz",
    component: () => import("@/views/new-quiz/QuizView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "new-quiz-result" });
      }

      return next();
    },
  },
  {
    path: "/new-quiz-result",
    name: "new-quiz-result",
    component: () => import("@/views/new-quiz-result/NewQuizResultView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (!credentials) {
        return next({ name: "quiz" });
      }

      return next();
    },
  },
  {
    path: "/old-quiz",
    name: "old-quiz",
    component: () => import("@/views/quiz/QuizView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "quiz-result" });
      }

      return next();
    },
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
  },
  {
    path: "/quiz-result",
    name: "quiz-result",
    component: () => import("@/views/quiz-result/QuizResultView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (!credentials) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/quiz-none",
    name: "quiz-none",
    component: () => import("@/views/quiz/QuizViewNone.vue"),
    beforeEnter: beforeEnter,
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
  },
  {
    path: "/landing-wl",
    name: "landing-wl",
    component: () => import("@/views/new-landing/LandingView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/sales-landing-wl",
    name: "sales-landing-wl",
    component: () => import("@/views/sales-landing/SalesLandingView.vue"),
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
  },
  {
    path: "/checkout-wl",
    name: "checkout-wl",
    props: true,
    component: () => import("@/views/checkout/CheckoutView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/did-you-know-wl",
    name: "did-you-know-wl",
    component: () => import("@/views/checkout/DidYouKnowView.vue"),
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
  },
  {
    path: "/old-landing-wl",
    name: "old-landing-wl",
    component: () => import("@/views/landing/LandingView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const { token } = to.query;

      const credentials = register.getCredentials();

      if (!credentials && !token) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/finish-quiz-wl",
    name: "finish-quiz-wl",
    component: () => import("@/views/new-quiz/QuizInformationLoaderView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "new-quiz-result" });
      }

      return next();
    },
  },
  {
    path: "/login-after-quiz-wl",
    name: "login-after-quiz-wl",
    component: () => import("@/views/new-quiz/LoginAfterQuizView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "new-quiz-result" });
      }

      return next();
    },
  },
  {
    path: "/quiz-wl",
    name: "quiz-wl",
    component: () => import("@/views/new-quiz/QuizView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "new-quiz-result" });
      }

      return next();
    },
  },
  {
    path: "/new-quiz-result-wl",
    name: "new-quiz-result-wl",
    component: () => import("@/views/new-quiz-result/NewQuizResultView.vue"),
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (!credentials) {
        return next({ name: "quiz" });
      }

      return next();
    },
  },
  {
    path: "/old-quiz-wl",
    name: "old-quiz-wl",
    component: () => import("@/views/quiz/QuizView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (credentials) {
        return next({ name: "quiz-result" });
      }

      return next();
    },
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
  },
  {
    path: "/quiz-result-wl",
    name: "quiz-result-wl",
    component: () => import("@/views/quiz-result/QuizResultView.vue"),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = isUserLoggedIn();

      if (isLoggedIn) {
        return next({ name: "dashboard" });
      }

      const credentials = register.getCredentials();

      if (!credentials) {
        return next({ name: "quiz" });
      }

      return next();
    },
    meta: {
      layout: LandingLayout,
      noAuth: true,
    },
  },
  {
    path: "/quiz-none-wl",
    name: "quiz-none-wl",
    component: () => import("@/views/quiz/QuizViewNone.vue"),
    beforeEnter: beforeEnter,
    meta: {
      layout: QuizLayout,
      noAuth: true,
    },
  },
];
