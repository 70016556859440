import Model from "@/models/Model";
import Measure from "@/models/Measure";
import UserProgress from "@/models/UserProgress";

import unitSystems from "@/db/unitSystems";
import parameters from "@/db/parameters";

import { convert } from "@/functions/convert";
import Plan from "@/models/Plan";

const measure = new Measure(parameters);

/**
 * @class User
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} fullName
 *
 * @property {Object} user_plan
 * @property {string} user_plan.expire_at
 */
export default class User extends Model {
  constructor(data = {}) {
    super(data);

    this.loaded = new Promise((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });

    this.load(data);

    this.progress = new UserProgress();
  }

  load(data) {
    this.email = data.email || "";
    this.allergens = data.allergens || [];
    this.info = data.info || {};
    this.gender = data.gender || {};
    this.body = data.body || {};
    this.notifications = data.notifications || [];
    this.favorite = data.favorite || [];
    this.trainings = data.trainings || [];
    this.photos = data.photos || [];
    this.user_plan = data.user_plan || null;
    this.plan = new Plan(data.plan || {});

    this.age = getAge(this.info.birthdate);

    if (Object.keys(data).length > 0) {
      measure.unitSystem = this.unitSystem;
      this.resolvePromise(true);
    }
  }

  setTrainings(data = []) {
    this.trainings = [...data];
  }

  setFavoriteFood(data = []) {
    this.favorite = [...data];
  }

  bodyParam(param, obj = null) {
    let { amount, unit } = obj || { amount: "", unit: {} };

    const system = unitSystems.find((item) => item.id === unit.unit_system_id);

    const userSystem = this.unitSystem;

    if (system.id !== userSystem.id) {
      const systemUnit = measure[param];

      if (systemUnit) {
        amount = convert(amount, unit.code, systemUnit.code).toFixed(2);
        unit = systemUnit;
      }
    }

    return {
      amount: amount,
      unit: unit.code || "",
      date: dateFormat(obj.created_at, "."),
    };
  }

  get unitSystem() {
    return this.info ? this.info.unit_system : {};
  }

  get fullName() {
    const { first_name, last_name } = this.info;

    return first_name + " " + last_name;
  }

  get firstName() {
    return this.info.first_name;
  }

  get lastName() {
    return this.info.last_name;
  }

  get weight() {
    return this.bodyParam("weight", this.body.weight);
  }

  get weightIdeal() {
    return this.bodyParam("weight_ideal", this.body.weight_ideal);
  }

  get weightStart() {
    return this.bodyParam("weight_start", this.body.weight_start);
  }

  get height() {
    return this.bodyParam("height", this.body.height);
  }

  get biceps() {
    return this.bodyParam("biceps", this.body.biceps);
  }

  get breast() {
    return this.bodyParam("breast", this.body.breast);
  }

  get thign() {
    return this.bodyParam("thign", this.body.thign);
  }

  get accessDenied() {
    if (!this.user_plan) return true;

    const now = new Date();
    const expireAt = new Date(this.user_plan.expire_at);
    now.setHours(0, 0, 0, 0);

    return expireAt < now;
  }
}

function getAge(date) {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
