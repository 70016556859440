import i18n from "@/libs/i18n/index.js";

export default [
  {
    id: 1,
    code: "minimal",
    name: "",
    desc: i18n.t("quiz.6.option.minimal"),
  },
  {
    id: 2,
    code: "light",
    name: "",
    desc: i18n.t("quiz.6.option.light"),
  },
  {
    id: 3,
    code: "1-3-times",
    name: "",
    desc: i18n.t("quiz.6.option.1-3-times"),
  },
  {
    id: 4,
    code: "3-5-times",
    name: "",
    desc: i18n.t("quiz.6.option.3-5-times"),
  },
  {
    id: 5,
    code: "every-day",
    name: "",
    desc: i18n.t("quiz.6.option.every-day"),
  },
  {
    id: 6,
    code: "hard-work",
    name: "",
    desc: i18n.t("quiz.6.option.hard-work"),
  },
];
