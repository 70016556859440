import activity from "@/db/physical-activity";
import ageOptions from "@/db/age";
import timeToEatOptions from "@/db/time-to-eat";
import allergens from "@/db/allergens";
import cookingTimes from "@/db/cooking-times";
import genders from "@/db/genders";
import goals from "@/db/goals";
import meals from "@/db/meals";
import diets from "@/db/diets";
import units from "@/db/units";
import unitSystems from "@/db/unitSystems";
import parameters from "@/db/parameters";

export default {
  activities: activity,
  allergens: allergens,
  cookingTimes: cookingTimes,
  meals: meals,
  diets: diets,
  genders: genders,
  goals: goals,
  parameters: parameters,
  units: units,
  unitSystems: unitSystems,
  ageOptions,
  timeToEatOptions,
};
