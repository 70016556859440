<template>
  <div class="simple-layout">
    <div class="simple-layout__header">
      <router-link to="/landing" class="sign-white-part__logo">
        <img src="@/assets/img/sign/logo.svg" alt="BalanceFit" class="sign-white-part__logo-img" />
      </router-link>
    </div>

    <div class="simple-layout__body">
      <slot />
    </div>

    <div class="simple-layout__footer">
      <div class="simple-layout__text">© 2022 BalanceFit. All Rights Reserved</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleLayout",
};
</script>

<style lang="scss" scoped>
.simple-layout {
  height: 100vh;

  display: flex;
  flex-flow: column;
  margin: 0 auto;
  max-width: 800px;
  padding: 50px 0 40px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    max-width: 410px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    max-width: 564px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    max-width: 720px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    max-width: 800px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    max-width: 800px;
  }
}

.simple-layout__header {
  min-height: 75px;
}

.simple-layout__body {
  display: flex;
  align-items: center;
  flex: 1;
}

.simple-layout__footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.simple-layout__text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #292929;
}
</style>
