import { mapActions, mapGetters, mapMutations } from "vuex";

import Measure from "@/models/Measure";
import parameters from "@/db/parameters";

const measure = new Measure(parameters);

/**
 * @property {User} user
 * @property {boolean} loading
 * @property {boolean} userLoaded
 */
export default {
  components: {},
  props: {},

  computed: {
    ...mapGetters("app", ["user", "loading", "userLoaded"]),
    unit() {
      measure.unitSystem = this.user.unitSystem;

      return measure;
    },
  },
  methods: {
    ...mapActions("app", ["fetchUser"]),
    ...mapMutations("app", ["setLoading"]),
  },
};
