export default {
  length: [
    {
      id: 2,
      unit_system_id: 1,
      unit_type_id: 1,
      code: "cm",
      name: "centimetre",
      desc: null,
      type: { id: 1, code: "length", name: "Length", desc: null },
    },
    {
      id: 5,
      unit_system_id: 2,
      unit_type_id: 1,
      code: "ft",
      name: "foot",
      desc: null,
      type: { id: 1, code: "length", name: "Length", desc: null },
    },
  ],
  mass: [
    {
      id: 10,
      unit_system_id: 1,
      unit_type_id: 2,
      code: "kg",
      name: "kilograms",
      desc: null,
      type: { id: 2, code: "mass", name: "Mass", desc: null },
    },
    {
      id: 12,
      unit_system_id: 2,
      unit_type_id: 2,
      code: "lb",
      name: "pound",
      desc: null,
      type: { id: 2, code: "mass", name: "Mass", desc: null },
    },
  ],
};
