<template>
  <button
    class="bf-btn"
    type="button"
    :class="{
      ...classMap,
      '--text': text,
      '--outlined': outlined,
      '--round': round,
      '--large': large,
    }"
    @click="handleClick"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
import "@/assets/scss/components/bf-btn.scss";
import classState from "@/mixins/class-state";

export default {
  name: "Button",
  mixins: [classState],
  emit: ["click"],
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped></style>
