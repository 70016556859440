export default [
  {
    id: 1,
    code: "breakfast",
    name: "Breakfast",
  },
  {
    id: 2,
    code: "dinner",
    name: "Dinner",
  },
  {
    id: 3,
    code: "lunch",
    name: "Lunch",
  },
];
