export default {
  namespaced: true,
  state: {
    paymentForm: null,
    stripe: null,
    stripeElements: null,
  },
  mutations: {
    setStripeElements(state, payload) {
      state.stripeElements = payload;
    },
    setPaymentForm(state, payload) {
      state.paymentForm = payload;
    },
    setStripe(state, payload) {
      state.stripe = payload;
    },
  },
  getters: {
    paymentForm: (state) => state.paymentForm,
    stripe: (state) => state.stripe,
    stripeElements: (state) => state.stripeElements,
  },
  actions: {},
};
