import api from "@/api";

import User from "@/models/User";
import UserProgress from "@/models/UserProgress";

export default {
  namespaced: true,
  state: {
    loading: false,
    globalOverlay: false,

    user: new User(),
    userLoaded: false,

    progress: new UserProgress(),
    progressLoaded: false,
  },
  mutations: {
    setUser(state, payload) {
      localStorage.setItem("user", JSON.stringify(payload));
      state.user.load(payload);
    },
    setProgress(state, payload) {
      state.progress.load(payload);
      state.user.progress.load(payload);
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setLoading(state, payload) {
      state.loading = !!payload;
    },
    setGlobalOverlay(state, payload) {
      state.globalOverlay = !!payload;
    },
    setUserLoaded(state, payload) {
      state.userLoaded = !!payload;
    },
    setProgressLoaded(state, payload) {
      state.progressLoaded = !!payload;
    },
  },
  getters: {
    /**
     * @param state
     * @return {User}
     */
    user(state) {
      return state.user;
    },
    /**
     * @param state
     * @return {UserProgress}
     */
    progress(state) {
      return state.progress;
    },
    loading(state) {
      return state.loading;
    },
    globalOverlay(state) {
      return state.globalOverlay;
    },
    userLoaded(state) {
      return state.userLoaded;
    },
    progressLoaded(state) {
      return state.progressLoaded;
    },
  },
  actions: {
    fetchUser(context) {
      context.commit("setLoading", true);
      context.commit("setUserLoaded", false);

      return new Promise((resolve, reject) => {
        api
          .get("/auth/user")
          .then((res) => {
            context.commit("setUser", res.data);
            resolve(res);
            context.commit("setUserLoaded", true);
          })
          .catch((res) => {
            reject(res);
            context.commit("setUserLoaded", false);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },
    fetchProgress(context) {
      context.commit("setLoading", true);
      context.commit("setProgressLoaded", false);

      return new Promise((resolve, reject) => {
        api
          .get("/user/progress")
          .then((res) => {
            context.commit("setProgress", res.data);
            resolve(res);
          })
          .catch((res) => {
            reject(res);
          })
          .finally(() => {
            context.commit("setLoading", false);
            context.commit("setProgressLoaded", true);
          });
      });
    },
  },
};
