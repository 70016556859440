<template>
  <div class="quiz-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "QuizLayout",
  components: {},
};
</script>

<style lang="scss"></style>
